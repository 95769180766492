export const nextArrow = ">";
export const regexEnglishPattern = /[^A-Za-z ]/g;
export const regexHebrewPattern = /[^א-ת]/g;

export const alefBetKeys = [
  "א",
  "ב",
  "ג",
  "ד",
  "ה",
  "ו",
  "ז",
  "ח",
  "ט",
  "י",
  "כ",
  "ך",
  "ל",
  "מ",
  "ם",
  "נ",
  "ן",
  "ס",
  "ע",
  "פ",
  "ף",
  "צ",
  "ץ",
  "ק",
  "ר",
  "ש",
  "ת",
  ".",
  "?",
  "space",
  "delete",
];
